<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <path
      d="M7.94278 18.2578C8.71499 19.0065 9.5908 19.2513 10.4619 19.0677C11.7568 18.7946 12.3972 18.5874 12.2182 17.382L11.3424 11.5339C11.5166 8.36495 13.16 5.38909 15.9004 3.55743C19.5684 1.10422 24.3807 1.2596 27.8792 3.94824C30.3136 5.81287 31.7309 8.61451 31.891 11.548L31.2788 15.6398C29.9086 19.3879 26.5137 21.9871 22.5867 22.3543H19.9687C19.2953 22.3543 18.7491 22.8958 18.7491 23.5739V24.2143C18.7491 24.8876 19.2953 25.4338 19.9687 25.4338H23.2694C23.9428 25.4338 24.4843 24.8923 24.4843 24.2143V23.8799C27.446 23.1548 30.031 21.3373 31.7167 18.7946L32.7715 19.0724C33.6284 19.2984 34.5184 19.0112 35.2906 18.2625C36.0628 17.5139 36.6797 16.3084 36.9057 14.8441C37.1317 13.375 36.9104 11.9577 36.416 10.8794C35.9169 9.80109 35.1729 9.11834 34.3206 8.87349C33.9628 8.7699 33.5767 8.73223 33.2376 8.72281C32.536 6.30727 31.0811 4.10834 28.9904 2.50269C26.9328 0.925291 24.4843 0.0871514 22.0122 0.00710438C19.5402 -0.0776513 17.0399 0.595685 14.8833 2.04124C12.4584 3.66102 10.768 6.0483 9.98633 8.72281C9.76973 8.69927 9.51546 8.70869 9.2094 8.79344C8.0652 9.11834 7.20352 10.0554 6.81741 10.8794C6.31829 11.9529 6.10169 13.3797 6.32771 14.8441C6.54902 16.3084 7.17056 17.5139 7.94278 18.2625V18.2578Z"
      fill="#33888C"
    />
    <path
      d="M29.8995 12.1083C29.8995 7.34786 26.1655 3.73633 21.5275 3.73633C16.8895 3.73633 13.1791 7.35257 13.1603 12.1083C13.1555 13.0171 13.3203 13.907 13.4569 14.8017L14.1679 19.4067C14.2809 20.1365 14.5681 20.4756 15.6182 20.4756H21.5275C26.1655 20.4756 29.8995 16.864 29.8995 12.1036V12.1083ZM15.6135 11.7881C15.6135 11.5338 15.637 11.2843 15.6605 11.0394H21.5275C24.2115 11.0394 25.7841 9.76811 26.2362 8.05416C26.9896 9.09006 27.4416 10.3802 27.4416 11.7881C27.4416 15.2066 24.8047 17.9612 21.5322 17.9612C18.2597 17.9612 15.6182 15.2066 15.6182 11.7881H15.6135Z"
      fill="#33888C"
    />
    <path
      d="M31.4624 25.7728C32.1075 25.7728 32.7337 25.8434 33.3458 25.9611C32.1781 24.9346 30.709 24.1106 28.7408 23.555C27.7943 24.1295 26.782 24.6003 25.7178 24.9393C25.36 26.0553 24.4795 26.9641 23.2646 26.9641H19.9639C18.4618 26.9641 17.2046 25.7069 17.2046 24.2048V23.9176L15.8626 23.1642C9.18107 24.5485 6.91621 28.5038 5.61191 34.2013C5.25405 35.741 5.80967 36.1883 6.49714 36.4002C11.2387 37.8599 18.0851 38.3778 21.5318 38.3778C21.7202 38.3778 21.9556 38.3684 22.1628 38.3684C21.8897 37.4643 21.6967 36.5273 21.6967 35.5385C21.6967 30.1424 26.071 25.7681 31.4671 25.7681L31.4624 25.7728Z"
      fill="#33888C"
    />
    <path
      d="M31.5568 27.0582C26.8811 27.0582 23.0859 30.8487 23.0859 35.5291C23.0859 40.2095 26.8764 43.9999 31.5568 43.9999C36.2372 43.9999 40.0277 40.2095 40.0277 35.5291C40.0277 30.8487 36.2372 27.0582 31.5568 27.0582Z"
      fill="#33888C"
    />
    <path
      d="M35.1584 32.9111C35.5068 33.2548 35.5116 33.8198 35.1678 34.1683C35.1678 34.1683 35.1631 34.173 35.1584 34.1777L31.1843 38.1471C31.0195 38.3166 30.7888 38.4108 30.5534 38.4108C30.3179 38.4108 30.0872 38.3166 29.9224 38.1471L27.9401 36.1648C27.6057 35.8022 27.6246 35.2372 27.9824 34.9028C28.3262 34.5827 28.8582 34.5827 29.202 34.9028L30.5534 36.2542L33.8965 32.9158C34.2402 32.5673 34.8053 32.5626 35.1537 32.9064C35.1537 32.9064 35.1584 32.9111 35.1631 32.9158L35.1584 32.9111Z"
      fill="white"
    />
  </svg>
</template>
